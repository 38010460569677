import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { ApolloProvider as GraphProvider } from '@apollo/react-hooks';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import React, { useCallback } from 'react';

export const useGraphClient = () => {
	console.log('%c Executing useGraphClient', 'color: red', Date.now());

	const createClient = useCallback(() => {
		console.log('%c Executing createClient', 'color: blue', Date.now());

		const cache = new InMemoryCache();

		const httpLink = new HttpLink({
			uri: process.env.REACT_APP_API_HTTPS_ENDPOINT,
		});

		const wsLink = new WebSocketLink({
			uri: process.env.REACT_APP_API_WSS_ENDPOINT,
			options: {
				reconnect: true,
			},
		});
		const link = split(
			// split based on operation type
			({ query }) => {
				const definition = getMainDefinition(query);
				return (
					definition.kind === 'OperationDefinition' &&
					definition.operation === 'subscription'
				);
			},
			wsLink,
			httpLink
		);

		const GraphClient = new ApolloClient({
			cache,
			link: link,
		});

		return [GraphClient, GraphProvider];
	}, []);

	return createClient();
};

export const GraphClient = ({ children }) => {
	console.log('%c Rendering GraphClient', 'color: orange', Date.now());
	const [GraphClient, GraphProvider] = useGraphClient();
	return <GraphProvider client={GraphClient}> {children} </GraphProvider>;
};
