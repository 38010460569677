import React from 'react';

const ProfileList = ({ data }) => {
	const renderRows = data.map((profile) => (
		<tr key={profile._id}>
			<td> {profile._id} </td>
			<td> {profile.name} </td>
			<td> {profile.loginId} </td>
			<td>{profile.mobile}</td>
		</tr>
	));

	return (
		<div className='table-responsive currency-table '>
			<table className='table profileTable'>
				<thead className='table-active'>
					<tr>
						<th scope='col'>#</th>
						<th scope='col'>Name</th>
						<th scope='col'>LoginId</th>
						<th scope='col'>Mobile</th>
					</tr>
				</thead>
				<tbody>{renderRows}</tbody>
			</table>
		</div>
	);
};

export default ProfileList;
