import gql from 'graphql-tag';

export const GET_PROFILES = gql`
	query getProfiles {
		getProfiles {
			_id
			name
			loginId
			mobile
		}
	}
`;

export default GET_PROFILES;
