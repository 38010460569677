import gql from 'graphql-tag';

export const CREATE_PROFILE_MUTATION = gql`
	mutation($name: String!, $loginId: String!, $mobile: String!) {
		createProfile(
			input: { name: $name, loginId: $loginId, mobile: $mobile }
		) {
			_id
			name
			loginId
			mobile
		}
	}
`;
