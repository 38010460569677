import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import NavBar from './components/NavBar';
import { AppContextProvider } from './core/AppContextService';
import ProfilesPage from './containers/ProfilesPage';
import { GraphClient } from './graph';

const App = () => {
	console.log('%c Rendering App', 'color:blue', Date.now());
	return (
		<React.Fragment>
			<AppContextProvider>
				<GraphClient>
					<NavBar />
					<main className='container'>
						<Switch>
							<Route path='/profiles' component={ProfilesPage} />
							<Redirect to='/profiles' />
						</Switch>
					</main>
				</GraphClient>
			</AppContextProvider>
		</React.Fragment>
	);
};

export default App;
