const Reducer = (state, { type, payload }) => {
	switch (type) {
		case 'UPDATE_MOVIE_LIST':
			return {
				...state,
				movieList: payload,
			};

		case 'UPDATE_LOADING':
			return {
				...state,
				loading: payload,
			};
		default:
			return state;
	}
};

export default Reducer;
