import React from 'react';
import { Link } from 'react-router-dom';
import AddProfile from '../features/profiles/AddProfile';
const NavBar = () => {
	return (
		<nav className='navbar sticky-top navbar-dark bg-danger'>
			<Link className='navbar-brand' to='/'>
				Profiles
			</Link>

			<div className='navbar-nav'>
				<AddProfile />
			</div>
		</nav>
	);
};

export default NavBar;
