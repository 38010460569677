import React, { createContext, useContext, useReducer } from 'react';
import Reducer from './reducer';

const intialState = {
	movieList: [],
	loading: true,
	authenticate: false,
};
const AppContext = createContext(intialState);

export const AppContextProvider = ({ children }) => {
	console.log('%c Rendering AppContextProvider', 'color: green', Date.now());

	const initialState = useContext(AppContext);
	const [state, dispatch] = useReducer(Reducer, initialState);

	return (
		<AppContext.Provider value={{ state, dispatch }}>
			{children}
		</AppContext.Provider>
	);
};

export default AppContext;
