import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_PROFILE_MUTATION } from './graph/mutations';
import Loading from '../../components/Loading';
const AddProfile = () => {
	const [createProfile, { loading }] = useMutation(CREATE_PROFILE_MUTATION, {
		//		refetchQueries: ['getProfiles'],
	});

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const onProfileSubmit = async (event) => {
		event.preventDefault();

		if (loading) {
			return <Loading />;
		}

		const {
			formBasicName,
			formBasicEmail,
			formBasicMobile,
		} = event.currentTarget.elements;

		if (
			formBasicName.validity.valid &&
			formBasicEmail.validity.valid &&
			formBasicMobile.validity.valid
		) {
			const loginId = formBasicEmail.value;
			const name = formBasicName.value;
			const mobile = formBasicMobile.value;

			if (loginId && name && mobile) {
				await createProfile({
					variables: { name, loginId, mobile },
				});
				handleClose();
			}
		}
	};
	return (
		<>
			<Button
				variant='btn btn-outline-light btn-sm float-right'
				onClick={handleShow}
			>
				Add Profile
			</Button>

			<Modal size='md' show={show} onHide={handleClose} animation={false}>
				<Form onSubmit={onProfileSubmit}>
					<Modal.Header closeButton>
						<Modal.Title>New Profile</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group controlId='formBasicName'>
							<Form.Label>Name</Form.Label>
							<Form.Control type='text' />
						</Form.Group>

						<Form.Group controlId='formBasicEmail'>
							<Form.Label>Email</Form.Label>
							<Form.Control type='email' />
						</Form.Group>

						<Form.Group controlId='formBasicMobile'>
							<Form.Label>Mobile</Form.Label>
							<Form.Control type='tel' />
							<Form.Text className='text-muted'>
								We'll never share your data with anyone else.
							</Form.Text>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary' onClick={handleClose}>
							Cancel
						</Button>
						<Button variant='danger' type='submit'>
							Submit
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default AddProfile;
