import React from 'react';
import { useQuery, useSubscription } from '@apollo/react-hooks';
import Loading from '../components/Loading';
import Error from '../components/Error';
import { GET_PROFILES } from '../features/profiles/graph/queries';
import { PROFILE_ADDED_SUBSCRIPTION } from '../features/profiles/graph/subscriptions';
import ProfileList from '../features/profiles/ProfileList';

const ProfilesPage = () => {
	const { data = {}, loading, error } = useQuery(GET_PROFILES, {});
	let { data: subscription, error: subError } = useSubscription(
		PROFILE_ADDED_SUBSCRIPTION
	);

	if (subError) {
		console.log('subscrib error', subError);
	}

	if (subscription) {
		const profile = subscription.profileAdded;
		data.getProfiles = data.getProfiles || [];
		if (data.getProfiles.indexOf(profile) === -1) {
			data.getProfiles = [...data.getProfiles, profile];
		}

		subscription = null;
	}

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return <Error value={error} />;
	}

	if (data && data.getProfiles) {
		return <ProfileList data={data.getProfiles} />;
	}
};

export default ProfilesPage;
