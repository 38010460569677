import gql from 'graphql-tag';

export const PROFILE_ADDED_SUBSCRIPTION = gql`
	subscription {
		profileAdded {
			name
			loginId
			_id
			mobile
		}
	}
`;
