import React from 'react';
const Error = ({ value }) => {
	return (
		<div className='alert alert-danger' role='alert'>
			Error....
		</div>
	);
};

export default Error;
